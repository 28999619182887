import React from 'react';
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    h2: {
        fontFamily: "sans-serif",  
        textAlign: "left",
        color: "#7d5dc7",
        fontWeight: 'bold',
    },
    p:{
        fontFamily: "Arial, sans-serif", 
        textAlign: "left",
        color: "#333",
        fontSize: "18px",
    }
  }));

const TitleMessage = () =>{
    
    const classes = useStyles();
    return (
        <div className = "TitleMessage">
            <h2 className = {classes.h2}>Creative Custom Layers Inc.</h2>
            <p className = {classes.p}>
            At Creative Custom Layers, we are a team of dedicated IT consultants passionate about transforming 
            businesses through innovative technology solutions. With a proven track record of excellence, 
            our mission is to empower organizations with tailored strategies and cutting-edge technologies 
            that drive efficiency, enhance security, and fuel growth. Backed by a diverse team of experienced 
            professionals, we combine technical expertise with a deep understanding of business needs to deliver 
            impactful results. As your trusted partner, we pride ourselves on fostering collaborative relationships
            and being at the forefront of industry trends. Whether it's streamlining operations, implementing
            robust cybersecurity measures, or harnessing the potential of cloud computing, we are committed to
            delivering solutions that propel your business forward.

            </p>
        </div>
    );

}

export default TitleMessage
