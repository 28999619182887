import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles, TextField, Button } from '@material-ui/core';
import React from 'react';
import axios from 'axios';


import contactImage from "../../assets/img/ContactUs/ContactUs_img.jpeg";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px",
        margin: "10px",
        backgroundColor: "#fafafa",
        fontFamily: "sans-serif"
    },
    flexContainer: {
        display: "flex",
        flexDirection: "column reverse",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
    },
    mainContent: {
        flex: "2",
        maxWidth: "400px",
        
    },
    sideBar: {
        flex: "1",
    },
    h3: {
        margin: 10,
        letterSpacing: "2px",
        textTransform: "uppercase",
        color: "#16269e",
        textAlign: "center",
    },
    text: {
        display: "flex",
        width: "300px",
        marginTop: "6px",
        marginBottom: "16px",
    },
    Submit: {
        backgroundColor: "#37475a",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#e47911",
        },
    },
    circleDiv:{
        margin: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circle: {
        height: "5px",
        width: "5px",
        margin: "0 10px 0 10px",
        backgroundColor: "#16269e",
        borderRadius: "50%",
        display: "inline-block",
    },
    ContactImg: {
        maxHeight: "50vh",
        maxWidth: "80vw",
    },
}));
const Contact = () =>{
    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const submitValue = () => {
        // axios.defaults.headers.common['Authorization'] = "CloudinityKeyAPI2021";
        axios.put('https://mea1uvxd25.execute-api.us-east-2.amazonaws.com/dev/contactpage',{firstName: firstName, lastName: lastName, phone: phone,email: email})
        .then((response) => {alert("Response Submited")})
    }
    

    return (
        <div className = {classes.root} id = "Contact">
            <Container>
                <h3 className = {classes.h3}>Get in touch with us</h3>
                <div className = {classes.circleDiv}>
                    <div className = {classes.circle}></div>
                    <div className = {classes.circle}></div>
                    <div className = {classes.circle}></div>
                </div>
                <div className = {classes.flexContainer}>
                    <section className = {classes.mainContent}>
                        <div className = {classes.form}>
                            <TextField className = {classes.text} id="standard-basic" variant="outlined" label="First Name" onChange={e => setFirstName(e.target.value)}/>
                            <TextField className = {classes.text} id="standard-basic" variant="outlined" label="Last Name" onChange={e => setLastName(e.target.value)}/>
                            <TextField className = {classes.text} id="standard-basic" variant="outlined" label="phone number" onChange={e => setPhone(e.target.value)}/>
                            <TextField className = {classes.text} id="standard-basic" variant="outlined" label="Email" onChange={e => setEmail(e.target.value)}/>
                            <Button className = {classes.Submit} onClick = {submitValue}>Submit</Button>
                        </div>
                    </section>
                    <div className = {classes.sidebar}>
                        <img src = {contactImage} className = {classes.ContactImg}/>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Contact;