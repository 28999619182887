import React from "react";
import Slide1 from "../../assets/img/carousal/cclback.jpg";
import Typical from "react-typical";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Home: {
    margin: 0,
    padding: 0,
    fontFamily: "Arial, sans-serif"
  },
  Container: {
    margin: 0,
    padding: 0,
    maxHeight: "75vh",
    maxWidth: "100vw",
    textAlign: "center",
    color: "white",
  },
  img: {
    padding: 0,
    margin: 0,
    height: "75vh",
    width: "100%",
    backgroundSize: "cover",
    filter: "brightness(50%)",
    animation: "$fadeLeft 1s",
  },
  LandingText: {
    height: "20vh",
    width: "45vw",
    position: "absolute",
    top: "25%",
    left: "25%",
  },
  "@keyframes fadeLeft": {
    "from": {
        opacity: "0",
        transform: "translate3d(-100%,0,0)",
    },
    "to": {
        opacity: "1",
        transform: "translate(-100%,0,0)",
    },
},
}));

const MyCarousal = () => {
  const classes = useStyles();
  return (
    <div id="home"  className = {classes.Home}>
      <Container className = {classes.Container}>
          <img src = {Slide1} className = {classes.img} alt = "Background"/>  
        <div className = {classes.LandingText}>
          <h2>Searching for expert IT solutions to propel your business? Look no further</h2><h4> At Creative Custom Layers, we specialize in guiding businesses through the intricate world of technology.</h4>

        </div>
      </Container>
    </div>
  );
};

export default MyCarousal;
